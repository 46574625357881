import React, { FC, Fragment, ReactNode } from 'react'
import cls from "../builder_carousel.module.scss";
import {ReviewStar} from "../../../../svg";

interface IProps {
    card: Record<string, string>,
    eventListeners: {
        contentStyle: Record<string, string>,
        buttonStyle: Record<string, string>,
        onMouseOver: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOut: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOverButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void,
        onMouseOutButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void
    }
}

const TestimonialCard: FC<IProps> = ({card, eventListeners}) => {
    const {
        rate = 0,
        title = "",
        description = "",
        buttonContent = "",
        linkValue = "",
        targeting = "",
    } = card
    const stars = new Array(5).fill(1)

    const {
        contentStyle,
        buttonStyle,
        onMouseOver,
        onMouseOut,
        onMouseOverButton,
        onMouseOutButton
    } = eventListeners

    const handleButton = {
        true: <button
            className={cls["carousel_card_button"]}
            style={buttonStyle}
            onMouseOver={(e) => onMouseOverButton(e, "button")}
            onMouseOut={(e) => onMouseOutButton(e, "button")}
        >
            {buttonContent}
        </button>,
        false: <></>,
    }

    const TestimonialItem = ({children}: { children: ReactNode }) => {
        if (linkValue) return <a href={linkValue} target={targeting} rel="noreferrer" children={children}/>
        return <Fragment>{children}</Fragment>
    }

    return <div className={cls["carousel_card_wrapper"]}>
        <TestimonialItem children={<div className={cls.carousel_card}>
            <div className={`${cls["carousel_card_content"]} ${cls.testimonials}`}>
                <div
                    className={cls["carousel_card_title"]}
                    style={contentStyle}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {title}
                </div>
                <div
                    className={cls["carousel_card_description"]}
                    style={contentStyle}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {description}
                </div>
                <div className={cls.rate_wrapper}>
                    <div className={cls.rating}>
                        {
                            stars.map((el, index) => {
                                return <ReviewStar
                                    id={index + 1}
                                    className={`${index + 1 <= rate ? cls.checked : ""}`}
                                />
                            })
                        }
                    </div>
                </div>
                {handleButton[`${!!buttonContent}`]}
            </div>
        </div>}/>
    </div>
}

export default TestimonialCard
