import cls from "./formModal.module.scss"
import FormBuilderComponent from "../../FormBuilderComponent";
import React, {FC, LegacyRef, useEffect, useRef} from "react";
import {IFormComponentType} from "../../types";
import {CloseSvg} from "../../../../svg";
import {useDispatch} from "react-redux";
import {setFormPopupOpen} from "../../../../store/formbuilder";

interface IProps {
    form: {
        formComponentInfo: IFormComponentType,
        customClassNames: string
    };
    recaptchaActive: string;
}

const FormModal: FC<IProps> = ({ form, recaptchaActive }) => {
    const modalRef = useRef<Node>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as HTMLDivElement)) {
                dispatch(setFormPopupOpen({id: null, open: false}));
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])
    return (
        <div className={cls.popup_pos}>
            <div className={cls.main_popup} ref={modalRef as LegacyRef<HTMLDivElement>}>
                <CloseSvg
                    style={{ float: "right", width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={() => dispatch(setFormPopupOpen({id: null, open: false}))}
                />
                <FormBuilderComponent item={form as any} recaptchaActive={recaptchaActive}/>
            </div>
        </div>
    );
};

export default FormModal;
