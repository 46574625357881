import React, {FC} from 'react'
import cls from "../forms.module.scss"
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import shopApi from "../../../../api/shop";
import {CheckToastSvg, FailSvg} from "../../../../svg";
import {useToast} from "../../../../atoms/Toast/useToast";
import {useRouter} from "next/router";
import {addAndResetForm, setFormErrors, setFormPopupOpen} from "../../../../store/formbuilder";
import {useReCaptcha} from "next-recaptcha-v3";

interface IProps {
    formId: number,
    formCode: string,
    index: number,
    element: {
        className: string,
        label: string,
        name: string,
        style: string,
        subtype: "button" | "submit" | "reset",
        value: string,
    },
    formData: Array<{
        type: string,
        value: string | number | string[],
        multiple: boolean,
        values: Array<{
            label: string,
            value: string,
            selected: boolean,
        }>,
    }>;
    recaptchaActive: string
}

const Button: FC<IProps> = ({formId, formCode, index, element, formData, recaptchaActive}) => {
    const {
        className = "",
        label = "",
        name = "",
        style = "",
        subtype = "button",
        value = "",
    } = element

    const data = useSelector((state: IState) => (state.formBuilder.forms.find(el => el.id === formId) || {formData: []}).formData)
    const dispatch = useDispatch();
    const toast = useToast();
    const router = useRouter();
    const {executeRecaptcha} = useReCaptcha();

    const handleSubmit = async () => {
        const handleElementValue = (element: {
            type: string,
            label: string,
            value: string,
            name: string,
            values: Array<{
                label: string,
                value: string,
                selected: boolean,
            }>,
            multiple: boolean
        }): Record<string, Record<string, string | number | Array<string>>> => {
            const {
                label = "",
                value = "",
                name = "",
                values = [],
                multiple = false
            } = element

            return {
                "header": {
                    // heading: label
                },
                "text": {
                    [name]: value
                },
                "number": {
                    [name]: value
                },
                "date": {
                    [name]: value
                },
                "file": {
                    [name]: value
                },
                "button": {},
                "checkbox-group": {
                    [name]: values
                        .filter(el => el.selected)
                        .map(el => el.value)
                },
                "radio-group": {
                    [name]: (values
                        .find(el => el.selected) || {value: ""}).value
                },
                "textarea": {
                    [name]: value
                },
                "select": {
                    [name]: values
                        .filter(el => el.selected)
                        .map(el => el.value)
                },
            }
        }
        let newData: Record<string, string | number | Array<string>> = {}
        let formData = new FormData();
        for (const el of data) {
            newData = {
                ...newData,
                ...handleElementValue(el)[el.type],
                ...(await (async () => {
                    if (String(recaptchaActive) === "1") {
                        const token = await executeRecaptcha("form_submit");
                        return { re_token: token } as Record<string, string>
                    }
                    return {}
                })())
            }
        }

        for (let key in newData) {
            if (Array.isArray(newData[key])) {
                (newData[key] as Array<string>).map((el: string, index: number) => {
                    formData.append(key + '[' + index + ']', el);
                })
            } else {
                formData.append(key, newData[key] as string | Blob);
            }
        }

        shopApi.submitFormBuilder(formData, formCode)
            .then(res => {
                if (res.errors) {
                    dispatch(setFormErrors(res.errors))
                    toast.open(
                        <span className="d-flex chek-fms">
                                <FailSvg/>
                            {res.message}
                            </span>
                    )
                } else {
                    dispatch(setFormPopupOpen({id: null, open: false}))
                    if (res.type === "show_message") {
                        toast.open(
                            <span className="d-flex chek-fms">
                                    <CheckToastSvg/>
                                {res.data}
                                </span>
                        )
                    } else {
                        router.push(res.data)
                    }
                }
            })
    }

    const handleReset = () => {
        dispatch(addAndResetForm({
            id: formId,
            formData
        }))
    }

    const handleOnClickByType = {
        "submit": handleSubmit,
        "reset": handleReset,
        "button": () => {
        }
    }

    return <button
        className={`${cls.form_button} ${className}`}
        // type={subtype}
        onClick={handleOnClickByType[subtype]}
    >
        {label}
    </button>
}
export default Button
