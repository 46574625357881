import cls from "./customMobileMenu.module.scss";
import React, {FC, useState} from "react";
import {ArrowRoundedDown12x7Svg, ContactsSVG} from "../../../../../svg";
import Link from "next/link";
import Image from "next/image";
import {domainUrl} from "../../../../../helper";
import {ISocials} from "../../MainLayout";
import {TranslationsType} from "../../../../../types/homePageTypes";

interface IMobileMenuFooter {
    phoneValue: string;
    footerAddressValue: string;
    footerEmailValue: string
    socialsFooter: Array<ISocials>;
    dbName: string;
    translationsData: TranslationsType
    showMobileContactsStyle: "empty_contacts_mobile" | "";
    showMobileSocialsStyle: "empty_socials" | "";
}

const CustomMobileMenuFooter: FC<IMobileMenuFooter> = ({
                                                           phoneValue,
                                                           footerAddressValue,
                                                           footerEmailValue,
                                                           socialsFooter,
                                                           dbName,
                                                           translationsData,
                                                           showMobileContactsStyle,
                                                           showMobileSocialsStyle
                                                       }) => {
    const [storeOpen, setStoreOpen] = useState<boolean>(false);
    const handleFooterContactsDisplay: Record<string, {
        display: { display: "flex" | "none" };
        arrowClassName: string;
        divClassName: string
    }> = {
        "true": {
            display: {display: "flex"},
            arrowClassName: "rotT_fms",
            divClassName: cls["footer-title-opened"]
        },
        "false": {
            display: {display: "none"},
            arrowClassName: "",
            divClassName: ""
        }
    }

    return (
        <div className={cls["mobileMenuFooter"]}>
            <div className={cls["mobile-footer-dropdown"]}>
                <div className={`${cls["mobile-footer-dropdown-divs"]} ${cls[showMobileContactsStyle]}`}>
                    <div className={cls["footer-contacts"]}>
                        <div
                            className={`${cls["footer-title"]} ${handleFooterContactsDisplay[`${storeOpen}`].divClassName}`}
                            onClick={() => setStoreOpen(prevState => !prevState)}
                        >
                            <ContactsSVG className={cls["contacts-path"]}/>
                            {translationsData?.OurContacts}
                            <span className={cls["mobile-footer-arrow"]}>
                                <ArrowRoundedDown12x7Svg
                                    // className={handleFooterContactsDisplay[`${storeOpen}`].arrowClassName}
                                />
                            </span>
                        </div>
                        <ul style={handleFooterContactsDisplay[`${storeOpen}`].display}
                            className={cls["footer-contacts__contacts"]}>
                            <li className={cls["contactsAddress"]}>
                                <p>{footerEmailValue}</p>
                            </li>
                            <li className={cls["contactsAddress"]}>
                                <Link href={`tel:${phoneValue}`}>
                                    <a>{phoneValue}</a>
                                </Link>
                            </li>
                            <li className={cls["contactsAddress"]}>
                                <p>{footerAddressValue}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`${cls["mobile-footer-dropdown-last-divs"]} ${cls[showMobileSocialsStyle]}`}>
                    <div className={cls["footer-newsletter__social-links"]}>
                        <ul className={cls["social-links__list"]}>
                            {socialsFooter?.map(({name, link, icon}, index) => (
                                <li className={cls["social-links__item"]} key={index}>
                                    <Link href={link}>
                                        <a
                                            className={`${cls["social-links__link"]} ${cls["social-link-color"]}`}
                                            aria-label="socials"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Image
                                                src={domainUrl(`${dbName}/storage/${icon}`)}
                                                alt={name}
                                                layout="fill"
                                                objectFit='contain'
                                            />
                                        </a>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomMobileMenuFooter
