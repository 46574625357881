import cls from "./builder.module.scss";
import {VideoBackground} from "./VideoBackground";
import React, {useRef, CSSProperties, FC, Fragment} from "react";
import useIsInView from "../../hooks/useInView";
import {builderDataType} from "./types";

interface IColumn {
    animation: string;
    columnClassname: string;
    columnStyles: CSSProperties;
    videoSource: string;
    childIdArray: builderDataType['idArray'][number]['childIdArray'];
    showOneComponentChild: any;
    BuilderJSX: ({dataKey}: any) => React.JSX.Element;
    lcpElement?: boolean;
    isMobile: boolean
}

const Column: FC<IColumn> = (
    {
        animation,
        columnClassname,
        columnStyles,
        videoSource,
        childIdArray,
        showOneComponentChild,
        BuilderJSX,
        lcpElement,
        isMobile
    }
) => {
    const columnRef = useRef<HTMLDivElement>(null);
    const inView = useIsInView(columnRef, !!animation && animation !== "none");
    const dataAosPropShow = !!animation && animation !== "none" ? {"data-aos": animation} : {}
    const ChildComponentShow = () => {
        if (showOneComponentChild) return <BuilderJSX dataKey={showOneComponentChild}/>
        return <Fragment>
            {childIdArray?.map((elInEl, indElInEl: number) => (
                <div
                    key={indElInEl}
                    className={cls.builder_component}
                    style={lcpElement && isMobile ? {width: "100%"} : {}}
                >
                    <BuilderJSX dataKey={elInEl}/>
                </div>
            ))}
        </Fragment>
    }
    const { padding, ...otherClass } = columnStyles || {};
    return (
        <div className={`${columnClassname} ${!inView ? "dataAosRef" : ""}`} style={{ padding }}>
            <div
                ref={columnRef}
                {...dataAosPropShow}
                style={lcpElement && isMobile ? {...otherClass, width: "100%"} : otherClass}
            >
                <VideoBackground src={videoSource}/>
                <ChildComponentShow/>
            </div>
        </div>
    )
};

export default Column;
