import React, {FC} from "react";
import cls from "../../posts/posts.module.scss"
import {IPostData} from "../../../types/posts";
import {TranslationsType} from "../../../types/homePageTypes";
import PostCard from "../../posts/PostCard";
import animCls from "../builder.module.scss"

interface IProps {
    item: {
        customClassNames: string;
        buttonValue: string;
        settings: [];
        blog: {
            blogs: Array<any>,
            category: {
                id: number,
                slug: string
            },
            quantity: number
        };
        linkValueButton: {
            id: string;
            value: string;
            checked: boolean
        }
        postComponentInfo: Array<any>;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
        hoverAnimation: string
    }
    translationsData: TranslationsType;
}


const PostsComponent: FC<IProps> = (
    {
        item: {
            customClassNames,
            postComponentInfo: renderedPosts = [],
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
            } = {},
            hoverAnimation
        },
        translationsData
    }
) => {
    const postsList = renderedPosts?.map((post: IPostData) => {
        return <div key={post.id} className={cls.post_list_item}>
            <PostCard
                post={post}
                category={post.default_category}
                translationsData={translationsData}
            />
        </div>
    });

    const viewPosts = {true: postsList, false: ""}

    return <div className={`${cls.posts_list} ${customClassNames} ${animCls[hoverAnimation ?? "none"]}`} data-aos={dataAos}>
        {viewPosts[`${!!postsList?.length}`]}
    </div>
};

export default PostsComponent;