import React, {FC} from 'react'
import cls from "../builder.module.scss";

interface IProps {
    item: {
        hyperLink: string;
        hyperLinkName: string;
        customClassNames: string;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        },
        hoverAnimation: string
    }
}

const HyperLink: FC<IProps> = (
    {
        item: {
            hyperLink,
            customClassNames,
            hyperLinkName,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {},
            hoverAnimation
        }
    }
) => {
    return (
        <div data-aos={dataAos} style={parentStyles} className={`${customClassNames} ${cls[hoverAnimation ?? "none"]}`}>
            <a href={hyperLink} target={"_blank"} style={styles} rel="noreferrer">
                {hyperLinkName}
            </a>
        </div>
    )
}

export default HyperLink;
