import React, {FC} from "react";
import cls from "../builder.module.scss"

interface IProps {
    item: {
        settings: any,
        iFrameContent: string | TrustedHTML,
        customClassNames: string,
        convertedSettings: {
            styles: Record<string, string>,
            animationAos: { "data-aos": any },
            parentStyles: Record<string, string>
        }
    }
}

const MapComponent: FC<IProps> = (
    {
        item: {
            customClassNames,
            iFrameContent,
            convertedSettings: {
                styles,
                parentStyles = {},
                animationAos: {"data-aos": dataAos = null} = {},
            } = {},
        } = {},
    }
) => {
    return (
        <div
            data-aos={dataAos}
            style={parentStyles}
            className={cls.icon_component}
        >
            <div className={customClassNames}>
                <div
                    style={styles}
                    className="builder-iframe"
                    dangerouslySetInnerHTML={{__html: iFrameContent as string}}
                />
            </div>
        </div>
    )
}

export default MapComponent;