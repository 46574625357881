import React, {FC, Fragment, ReactNode} from 'react'
import cls from "../builder_carousel.module.scss"
import HoverImage from '../../HoverImage'

interface IProps {
    card: Record<string, string>,
    eventListeners: {
        contentStyle: Record<string, string>,
        buttonStyle: Record<string, string>,
        onMouseOver: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOut: (e: React.MouseEvent<HTMLDivElement>) => void,
        onMouseOverButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void,
        onMouseOutButton: (e: React.MouseEvent<HTMLButtonElement>, type: string) => void
    }
}

const ImageBoxCard: FC<IProps> = ({card, eventListeners}) => {
    const {
        image_path = "",
        hover_path = '',
        title = "",
        buttonContent = "",
        linkValue = "",
        targeting = "",
    } = card

    const {
        contentStyle,
        buttonStyle,
        onMouseOver,
        onMouseOut,
        onMouseOverButton,
        onMouseOutButton
    } = eventListeners

    const handleButton = {
        true: <button
            className={cls["carousel_card_button"]}
            style={buttonStyle}
            onMouseOver={(e) => onMouseOverButton(e, "button")}
            onMouseOut={(e) => onMouseOutButton(e, "button")}
        >
            {buttonContent}
        </button>,
        false: <></>,
    }

    const CarouselItem = ({children}: { children: ReactNode }) => {
        if (linkValue) return <a href={linkValue} target={targeting} rel="noreferrer" children={children}/>
        return <Fragment>{children}</Fragment>
    }

    return <div className={cls["carousel_card_wrapper"]}>
        <CarouselItem children={<div className={cls.carousel_card}>
            <div className={cls["carousel_card_image_wrapper"]}>
                <img
                    alt="carousel card image"
                    aria-hidden="true"
                    src={image_path}
                />
                <HoverImage
                    src={hover_path}
                    className={cls.carousel_card_hover}
                />
            </div>

            <div className={cls["carousel_card_content"]}>
                <div className={cls["carousel_card_title"]}
                     style={contentStyle}
                     onMouseOver={onMouseOver}
                     onMouseOut={onMouseOut}
                >
                    {title}
                </div>

                {handleButton[`${!!buttonContent}`]}
            </div>
        </div>}/>
    </div>
}

export default ImageBoxCard