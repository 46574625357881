import React, {FC} from 'react'
import Link from 'next/link'
import {domainUrl} from '../../../../../helper'
import cls from "./customSearch.module.scss"
import Image from "next/image";
import {IProduct, TranslationsType} from "../../../../../types/homePageTypes";
import {ICurrencies} from "../../MainLayout";

interface IProps {
    products: Array<IProduct>;
    selectedRate: ICurrencies;
    dbName: string;
    translationsData: TranslationsType;
}

const CustomSuggestions: FC<IProps> = (
    {
        products,
        selectedRate,
        dbName,
        translationsData,
    }
) => {

    const ProductsExist = ({ product }: { product: IProduct }) => {
        if (!products.length) {
            return (
                <div className={cls.search__fm_dr_Message}>
                    {translationsData?.NoMatchingItems}
                </div>
            )
        }

        const ListJsx = () => {
            return (
                <Link
                    className={cls["suggestions__item-name"]}
                    href={`/${product.flats[0].url_key}`}
                    key={product.id}
                >
                    <li className={cls.suggestions__item}>
                        <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                            <div className={`${cls["search-product-image__body-fms"]}`}>
                                <Link href={`/${product.flats[0].url_key}`}>
                                    <a>
                                        <Image
                                            src={domainUrl(`${dbName}${product?.images[0]}`)}
                                            alt={product?.flats[0]?.name}
                                            width={50}
                                            height={50}
                                            priority
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className={cls["suggestions__item-info"]}>
                            <a> {product?.flats[0]?.name}</a>
                            <div className={cls["suggestions__item-meta"]}>
                                {`SKU: ${product?.sku}`}
                            </div>
                        </div>
                        <div className={cls["suggestions__item-price"]}>
                            {selectedRate?.symbol}
                            {" "}
                            {product.flats[0]?.special_price ?? product.flats[0]?.price}
                            {' '}
                        </div>
                    </li>
                </Link>
            )
        }
        return <ListJsx />
    }
    return (
        <div className={`${cls.suggestions} ${cls[`suggestions--location--header`]} ${cls.search__suggestions}`}>
            <ul className={cls["suggestions__list"]}>
                {products.map((product: IProduct) => <ProductsExist product={product} key={product.id} />)}
            </ul>
        </div>
    )
};

export default CustomSuggestions
