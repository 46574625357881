import React, {FC, useEffect, useRef, useState} from "react";
import cls from "./builder.module.scss"
import {TranslationsType} from "../../types/homePageTypes";
import {builderDataType} from "./types";
import RenderBuilderComponent from "./RenderCustomElements";
import {ICurrencies} from "../ForCWV/Wrappers/MainLayout";
import {VideoBackground} from "./VideoBackground";
import Column from "./Column";

interface IProps {
    getBuilderData: Record<number, Array<builderDataType>>
    tab: number
    domain: string;
    dbName: string
    translationsData: TranslationsType,
    selectedLocale: string,
    currencies: Array<ICurrencies>;
    selectedCurrItems: {
        selectedCurrCode: string;
        selectedCurrRate: string;
        selectedCurrSymbol: string
    };
    isMobile: boolean,
    recaptchaActive: string
    backOrderValue: string;
    lcpElement?: boolean
}

const BuilderSection: FC<IProps> = ({getBuilderData: clientShowMap, tab, ...builderComponentProps}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [originalBuilderSections, setOriginalBuilderSections] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current) {
                setOriginalBuilderSections(window.scrollY >= 1)
            }
        }
        if (!originalBuilderSections && builderComponentProps.isMobile) {
            window.addEventListener("scroll", handleScroll)
        } else {
            window.removeEventListener("scroll", handleScroll)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    const BuilderJSX = ({dataKey}: any) => <RenderBuilderComponent data={dataKey} {...builderComponentProps}/>

    return (
        <div className="block-products">
            <div
                ref={!originalBuilderSections ? ref : null}
                className={`
                    ${tab !== 5 ? cls["home-product-container"] : ""}
                    ${cls["b-container"]}
                    ${tab === 5 ? cls.topped_margin_off : ""}
                `}
            >
                {(!originalBuilderSections && builderComponentProps.isMobile
                    ? {"1": [clientShowMap[1]?.[0]], "2": [], "3": [], "4": []}
                    : clientShowMap)[tab]?.map((
                    {
                        idArray,
                        parentCls,
                        container,
                        screenContainer,
                        rowStyles,
                        customClassName,
                        childWrapper,
                        parentAlignItems,
                        showOneComponent,
                        childVideos = [],
                        backgroundVideo
                    } = {} as builderDataType, ind: number) => {
                    const colStyles = showOneComponent ? idArray?.[0]?.columnStyles : {}
                    return <div key={ind} className={`${container} ${cls.builder_component}`}>
                        <div className={`${parentCls} ${customClassName}`} style={{ ...rowStyles, position: 'relative'}}>
                            <VideoBackground src={backgroundVideo} />
                            <div className={screenContainer} style={{...childWrapper, ...colStyles, alignItems: parentAlignItems, width: "100%"}}>
                                <VideoBackground src={showOneComponent ? childVideos[0] : backgroundVideo} />
                                {showOneComponent
                                    ? <BuilderJSX dataKey={showOneComponent}/>
                                    : idArray?.map((
                                    {
                                        childIdArray,
                                        showOneComponent: showOneComponentChild,
                                        childCls,
                                        secondChildCls,
                                        columnStyles,
                                        customClassName: customColumnClassName,
                                        animation,
                                        hoverAnimation
                                    }, index: number) => {
                                    const columnClassname = `${childCls} ${cls[secondChildCls]} ${customColumnClassName} ${cls[hoverAnimation]}`
                                    return (
                                        <Column
                                            key={index}
                                            childIdArray={childIdArray}
                                            showOneComponentChild={showOneComponentChild}
                                            videoSource={childVideos[index]}
                                            columnStyles={columnStyles}
                                            columnClassname={columnClassname}
                                            animation={animation}
                                            BuilderJSX={BuilderJSX}
                                            lcpElement={builderComponentProps.lcpElement}
                                            isMobile={builderComponentProps.isMobile}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default BuilderSection
