import React, {FC} from "react";
import CountUp from 'react-countup';
import cls from "../builder.module.scss"

interface IProps {
    item: {
        customClassNames: string;
        countUpSettings: {
            start: number,
            end: number,
            fontSize: number,
            fontWeight: number,
            color: string,
            delay: number,
            duration: number,
            prefix: string,
            suffix: string,
            scrollSpyDelay: number,
            enableScrollSpy: boolean,
            useGrouping: boolean,
            useEasing: boolean,
        },
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: Record<string, string>;
            parentStyles: Record<string, string>
        },
        hoverAnimation: string
    }
}

const CountUpComponent: FC<IProps> = (
    {
        item: {
            customClassNames,
            countUpSettings: {
                start = 0,
                end = 1000,
                fontSize = 16,
                fontWeight = 400,
                color = "#000",
                delay = 0,
                duration = 2,
                prefix = "",
                suffix = "",
                scrollSpyDelay = 500,
                enableScrollSpy = false,
                useGrouping = false,
                useEasing = false,
            } = {},
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                parentStyles = {},
                styles,
            } = {},
            hoverAnimation
        } = {},
    }
) => {

    return <div
        className='count_up'
        data-aos={dataAos}
        style={parentStyles}
    >
        <div className={`${customClassNames} ${cls[hoverAnimation ?? "none"]}`} style={styles}>
            <CountUp
                style={{
                    fontSize: `${fontSize}px`,
                    fontWeight,
                    color,
                }}
                start={start}
                end={end}
                delay={delay}
                duration={duration}
                prefix={prefix}
                suffix={suffix}
                scrollSpyDelay={scrollSpyDelay}
                enableScrollSpy={enableScrollSpy}
                useGrouping={useGrouping}
                useEasing={useEasing}
                redraw={true}
                // separator=":"
                // useIndianSeparators={true}
                // scrollSpyOnce={false}
            />
        </div>
    </div>
}

export default CountUpComponent