import React, {FC} from "react";
import cls from "../builder.module.scss"

interface IProps {
    item: {
        icon: string,
        settings: any,
        linkValue: string,
        isLinkChecked: boolean,
        customClassNames: string,
        convertedSettings: {
            styles: Record<string, string>,
            animationAos: { "data-aos": any },
            parentStyles: Record<string, string>
        }
        hoverAnimation: string
    }
}

const IconComponent: FC<IProps> = (
    {
        item: {
            icon,
            linkValue,
            customClassNames,
            isLinkChecked,
            convertedSettings: {
                styles,
                parentStyles = {},
                animationAos: {"data-aos": dataAos = null} = {},
            } = {},
            hoverAnimation
        } = {},
    }
) => {

    return (
        <div
            data-aos={dataAos}
            style={parentStyles}
            className={cls.icon_component}
        >
            <div className={`${customClassNames} ${hoverAnimation}`}>
                <a
                    href={linkValue}
                    rel={"noreferrer"}
                    target={isLinkChecked ? "_blank" : ""}
                    style={{pointerEvents: linkValue ? "unset" : "none"}}
                >
                    {icon &&
                        <div
                            style={styles}
                            className="builder-icon"
                            dangerouslySetInnerHTML={{__html: icon}}
                        />
                    }
                </a>
            </div>
        </div>
    )
}

export default IconComponent;