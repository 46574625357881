import React, {FC, memo, ReactNode, useEffect, useState} from "react";
import cls from "../builder.module.scss"

interface IProps {
    item: {
        customClassNames: string;
        videoLink: string
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: Record<string, string>;
            parentStyles: Record<string, string>
        }
        hoverAnimation: string
    }
}

const Video: FC<IProps> = (props) => {
    const {
        item: {
            customClassNames,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {},
            videoLink,
            hoverAnimation
        }
    } = props
    const [showIframe, setShowIframe] = useState<ReactNode | null>(null);
    useEffect(() => {
        setShowIframe(<iframe
            src={videoLink}
            loading="lazy"
            allow="autoplay"
            width="100%"
            height={styles?.height || "200px"}
            style={{border: "none"}}
            className={`${customClassNames} ${cls[hoverAnimation ?? "none"]}`}
        />)
    }, []);
    return (
        <div data-aos={dataAos} style={parentStyles}>
            {showIframe}
        </div>
    );
}

export default memo(Video)
