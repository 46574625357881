import {domainUrl} from "../../helper";
import {IBlogDataPost} from "../../types/pages";
import {ReactNode} from "react";
import {schemaCategoriesType} from "../../types/header";
import {IPostData} from "../../types/posts";
import {IProduct} from "../../types/homePageTypes";
import {useSelector} from "react-redux";
import {IState} from "../../types/state";

const logoPath = `/stores-logo.svg`;

export const schemaBlog = (domain: string, dbName: string, asPath: string, blogs: any[], forBlogType: string = "blog") => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Blog",
        "name": "Blogs",
        "url": domainUrl(dbName + asPath),
        "offers": blogs.map((post: IBlogDataPost) => ({
            "@type": "Product",
            "id": post.id,
            "name": forBlogType === "blog" ? post.blog_title : post.name,
            "description": forBlogType === "blog" ? post.html_content : post.short_description,
            "image": domainUrl(`${domain}/storage/${dbName}/${logoPath}`),
        }))
    }
}

export const schemaPost = (dbName: string, asPath: string, posts: any[]) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Post",
        "name": "Posts",
        "url": domainUrl(dbName + asPath),
        "offers": posts.map((post: IPostData, index: number) => ({
            "@type": "Product",
            "id": String(index + 1),
            "name": post.name,
            "description": post.short_description ?? post.description,
            "image": post.image,
        }))
    }
}

export const schemaPages = (dbName: string, asPath: string, content: any) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Page",
        "name": "Pages",
        "url": domainUrl(dbName + asPath),
        "meta_description": content?.data?.[0].meta_description,
        "meta_keywords": content?.data?.[0].meta_keywords,
        "meta_title": content?.data?.[0].meta_title,
        "html_content": content?.data?.[0].html_content,
    };
}

export const schemaCategoryPage = (productsList: {data: Array<IProduct>}, dbName: string) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "Product",
        "name": `Products in Category`,
        "offers": productsList?.data?.map((
            {
                id: productId = 0,
                flats: [{name: productName = "", description: productDesc = ""} = {}] = [],
                images: [productImg]
            }
        ) => ({
            "@type": "Product",
            "id": String(productId),
            "name": productName,
            "description": productDesc,
            "image": domainUrl(`${dbName}${productImg}`),
        }))
    }
}

export const schemaProduct = (asPath: any, product: any, dbName: string, domain: string) => {

    let brand = product.details.find((el: any) => el.attribute[0]?.code === "brand")?.attribute_value

    return {
        "@context": `https://schema.org/`,
        "@type": "Product",
        "name": product?.flats[0]?.name,
        "description": product?.flats[0]?.meta_description && product?.flats[0]?.description,
        "image": domainUrl(`${dbName}/storage/${product?.images[0]?.path}`),
        "url": domainUrl(`${dbName}${asPath}`),
        "brand": {
            "@type": "Brand",
            "name": brand || domain,
        },
        "review": {
            "@type": "Review",
            "name": "Organization Review",
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": "0"
            },
            "author": {
                "@type": "Organization",
                "name": domain
            },
        },
        "productID": product?.id,
        "sku": product?.sku,
    };
}

export const schemaString = (
    arr: Array<{
        title: string,
        description: string,
    }>
) => {
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": arr.map(({title, description}) => {
            return {
                "@type": "Question",
                "name": title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": description
                }
            }
        })
    }
}

export const schemaPageHeader = (dbName: string, asPath: string, breadcrumb: any) => {
    return {
        "@context": `https://schema.org/`,
        "@type": "BreadcrumbList",
        "name": "Breadcrumb",
        "url": domainUrl(dbName + asPath),
        "itemListElement": breadcrumb.map((item: any, index: number) => {
            return {
                "name": item.title,
                "@type": "ListItem",
                "id": String(index + 1),
                "position": index + 1,
                "item": domainUrl(dbName + item.url)
            };
        }),
    }
}

export const schemPageCart = (dbName: string, asPath: string, items: any, currency = "USD")  => {
    return {
        "@context": `https://schema.org/`,
        "@type": "ShoppingCart",
        "name": "Shopping Cart",
        "url": domainUrl(dbName + asPath),
        "priceCurrency": currency,
        "itemListElement": items.map((item: any) => {
            return {
                "@type": "Product",
                "name": item.name,
                "sku": item.sku,
                "price": item.price,
                "url": domainUrl(dbName + "/" + item.product.url_key),
                "image": item.product.base_image.original_image_url,
                "description": item.product.short_description ?? item.product.description,
            };
        }),
    }
}